dmx.Component('date-range-picker', {

  extends: 'date-picker',

  initialData: {
    start: '',
    end: '',
  },

  attributes: {
    startdate: {
      type: String,
      default: '',
    },

    enddate: {
      type: String,
      default: '',
    },

    autoapply: {
      type: Boolean,
      default: false,
    },

    separator: {
      type: String,
      default: ' - ',
    },

    unlinked: {
      type: Boolean,
      default: false,
    },

    maxspanYears: {
      type: Number,
      default: null,
    },

    maxspanMonths: {
      type: Number,
      default: null,
    },

    maxspanWeeks: {
      type: Number,
      default: null,
    },

    maxspanDays: {
      type: Number,
      default: null,
    },
  },

  methods: {
    setValue (startDate, endDate) {
      this._setValue(startDate, endDate);
    },
  },

  init (node) {
    dmx.Component('date-picker').prototype.init.call(this, node);
    
    this._setValue({ start: this.props.startdate, end: this.props.enddate }, true);
  },

  destroy () {
    if (this._daterangepicker) {
      this._daterangepicker.remove();
    }

    if (this._input) {
      this._input.remove();
    }

    if (this._input_start) {
      this._input_start.remove();
    }

    if (this._input_end) {
      this._input_end.remove();
    }
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('name')) {
      this._input.name = this.props.name;
      this._input_start.name = this.props.name + '_start';
      this._input_end.name = this.props.name + '_end';
      if (updatedProps.size === 1) return;
    }

    if (updatedProps.has('disabled')) {
      this.$node.disabled = this.props.disabled;
      this._input.disabled = this.props.disabled;
      this._input_start.disabled = this.props.disabled;
      this._input_end.disabled = this.props.disabled;
      if (updatedProps.size === 1) return;
    }

    if (updatedProps.has('startdate') || updatedProps.has('enddate')) {
      this._setValue({ start: this.props.startdate, end: this.props.enddate }, true);
      if (updatedProps.has('startdate') && updatedProps.has('enddate') && updatedProps.size === 2) return;
      if (updatedProps.size === 1) return;
    }

    if (!this.props.format) {
      this.props.format = this.props.timepicker ? 'L LT' : 'L';
    }

    this._createDaterangePicker();
  },

  _createHiddenInput () {
    dmx.Component('date-picker').prototype._createHiddenInput.call(this);

    this._input_start = document.createElement('input');
    this._input_start.type = 'hidden';
    this._input_start.name = this.$node.name + '_start';
    this._input_start.value = this.$node.value;
    this.$node.parentNode.insertBefore(this._input_start, this.$node);

    this._input_end = document.createElement('input');
    this._input_end.type = 'hidden';
    this._input_end.name = this.$node.name + '_end';
    this._input_end.value = this.$node.value;
    this.$node.parentNode.insertBefore(this._input_end, this.$node);
  },

  _createDaterangePicker () {
    const $node = $(this.$node);

    $node.daterangepicker({
      parentEl: $node.closest('.modal, .offcanvas')[0] || document.body,
      autoUpdateInput: false,
      autoApply: this.props.autoapply,
      linkedCalendars: !this.props.unlinked,
      showWeekNumbers: this.props.showweeknumbers,
      showDropdowns: this.props.showdropdowns,
      minYear: this.props.minyear,
      maxYear: this.props.maxyear,
      opens: this.props.opens,
      drops: this.props.dropsup ? 'up' : this.props.drops,
      minDate: this._formatDate(this.props.mindate),
      maxDate: this._formatDate(this.props.maxdate),
      maxSpan: this._getMaxSpan(),
      locale: {
        format: this.props.format,
        separator: this.props.separator,
        direction: this.props.direction,
        weekLabel: this.props.weeklabel,
        applyLabel: this.props.applylabel,
        cancelLabel: this.props.cancellabel,
      },
      buttonClasses: '',
      applyButtonClasses: '',
      cancelButtonClasses: '',
      isCustomDate: this._isCustomDate,
      isInvalidDate: this._isInvalidDate,
      timePicker: this.props.timepicker,
      timePicker24Hour: this.props.use24hours,
      timePickerIncrement: this.props.minutesIncrement,
    }, this._updateValue);

    $node.on('apply.daterangepicker', this._applyHandler);
    $node.on('change.daterangepicker', this._changeHandler);

    $node.on('show.daterangepicker', this.dispatchEvent.bind(this, 'show'));
    $node.on('hide.daterangepicker', this.dispatchEvent.bind(this, 'hide'));
    $node.on('apply.daterangepicker', this.dispatchEvent.bind(this, 'apply'));
    $node.on('cancel.daterangepicker', this.dispatchEvent.bind(this, 'cancel'));

    this._daterangepicker = $node.data('daterangepicker');
  },

  _updateValue (startDate, endDate) {
    let start = this.props.utc ? startDate.toISOString() : startDate.format('YYYY-MM-DD HH:mm:ss');
    let end = this.props.utc ? endDate.toISOString() : endDate.format('YYYY-MM-DD HH:mm:ss');
    let prevStart = this.data.start;
    let prevEnd = this.data.end;

    this._setValue({ start, end });

    if (this.data.value !== prevStart || this.data.value !== prevEnd) {
      this.dispatchEvent('change');
      this.dispatchEvent('changed');
    }
  },

  _updateData (event) {
    if (event && this.$node.dirty) {
      dmx.validate(this.$node);
    }

    this.set('disabled', this.$node.disabled);
    if (this._input.value !== this.data.value || this._input_start !== this.data.start || this._input_end !== this.data.end) {
      this.set({
        value: this._input.value,
        start: this._input_start.value,
        end: this._input_end.value,
      });
      dmx.nextTick(() => this.dispatchEvent("updated"));
    }

    if (this.$node.dirty) {
      this.set('invalid', !this.$node.validity.valid);
      this.set('validationMessage', this.$node.validationMessage);
    }
  },

  _setValue (value, defaultValue) {
    let { start = '', end = '' } = value;

    if (typeof start !== 'string') start = '';
    if (typeof end !== 'string') end = '';
    
    if (start == 'now' || start == 'today') {
      start = this.props.utc ? moment().toISOString() : moment().format('YYYY-MM-DD HH:mm:ss');
    }

    if (end == 'now' || end == 'today') {
      end = this.props.utc ? moment().toISOString() : moment().format('YYYY-MM-DD HH:mm:ss');
    }

    if (!this.props.timepicker) {
      start = start.substring(0, 10);
      end = end.substring(0, 10);
    }

    if (start) {
      this._daterangepicker.setStartDate(this._formatDate(start));
    }

    if (end) {
      this._daterangepicker.setEndDate(this._formatDate(end));
    }

    this.$node.value = start && end ? this._formatDate(start) + this.props.separator + this._formatDate(end) : '';
    this._input.value = start && end ? start + '/' + end : '';
    this._input_start.value = start || '';
    this._input_end.value = end || '';

    if (defaultValue) {
      this.$node.defaultValue = this.$node.value;
      this._input.defaultValue = this._input.value;
      this._input_start.defaultValue = this._input_start.value;
      this._input_end.defaultValue = this._input_end.value;
    }

    this._updateData(true);
  },

  _getMaxSpan () {
    const { maxspanYears: years, maxspanMonths: months, maxspanWeeks: weeks, maxspanDays: days } = this.props;

    if (years || months || weeks || days) {
      return { years, months, weeks, days };
    }
  },

  _applyHandler (event) {
    const prevStart = this.data.start;
    const prevEnd = this.data.end;

    this._updateValue(this._daterangepicker.startDate, this._daterangepicker.endDate);
    
    if (this._input_start.value !== prevStart || this._input_end.value !== prevEnd) {
      this.dispatchEvent('changed');
    }
  },

});
